import {
    RadarEntry, RadarEntrySnapshot,
    TechRadarApi,
    TechRadarLoaderResponse,
} from '@backstage-community/plugin-tech-radar';
import {OAuthApi} from '@backstage/core-plugin-api';

export class TechRadarClient implements TechRadarApi {

    readonly #gitLabAuthApi: OAuthApi;

    constructor(
        gitLabAuthApi: OAuthApi,
    ) {
        this.#gitLabAuthApi = gitLabAuthApi;
    }

    async load(): Promise<TechRadarLoaderResponse> {

        // Entry represents the data we need to give to the Tech Radar
        // some of what we have - in the Gitlab Skills Radar repo - is in a slightly different shape
        interface Entry {
            // it needs an 'id' but our source data doesn't have it
            label: string; // --> title
            link: string; // --> url
            quadrant: number;
            ring: number; // --> timeline.ringId
            roll: string; // --> description
            // it needs a 'timeline' but our source data doesn't have it
        }

        const oauthToken = await this.#gitLabAuthApi.getAccessToken(['read_api']);
        const skillsRadarUrl = "https://gitlab.com/api/v4/projects/39916958/repository/files/src%2Fentries.json/raw";
        const response = await fetch(skillsRadarUrl, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${oauthToken}`
            },
        });

        if (!response.ok) {
            const message = `Couldn't retrieve Skills Radar data.`;
            throw new Error(message);
        }

        let entries: Entry[] = []
        entries = await response.json();

        let idCounter = 0;
        const radarEntries: Array<RadarEntry> = [];

        // Map to new shape
        for (let i = 0; i < entries.length; i++) {
            radarEntries.push({
                id: String(idCounter),
                quadrant: String(entries[i].quadrant),
                title: entries[i].label,
                url: entries[i].link,
                description: entries[i].roll,
                key: String(idCounter),
                timeline: new Array<RadarEntrySnapshot>({
                    date: new Date(),
                    ringId: String(entries[i].ring)
                }),
            })
            idCounter++;
        }

        return {
            rings: [
                {id: "0", name: "BEGINNER", color: "#93c47d"},
                {id: "1", name: "NOVICE", color: "#93d2c2"},
                {id: "2", name: "PRO", color: "#fbdb84"},
                {id: "3", name: "VISIONARY", color: "#fbdb84"},
            ],
            quadrants: [
                {id: "0", name: "CORE"},
                {id: "1", name: "CLOUD"},
                {id: "2", name: "AGILE"},
                {id: "3", name: "INFRASTRUCTURE-AS-CODE"},
            ],
            entries: radarEntries,
        };
    }

}
