import { useWorkspacesCardContext } from "@coder/backstage-plugin-coder";
import { makeStyles, Typography } from "@material-ui/core";
import React from "react";

interface HeaderRowProps {
    actions?: React.ReactNode;
    headerLevel?: string;
    className?: string;
    headerClassName?: string;
    hgroupClassName?: string;
    subheaderClassName?: string;
    activeRepoFilteringText?: string;
    headerText?: string;
    [key: string]: any;
  }
  
  const CoderWorkspaceCardHeader: React.FC<HeaderRowProps> = ({
    actions,
    headerLevel,
    className,
    headerClassName,
    hgroupClassName,
    subheaderClassName,
    activeRepoFilteringText,
    headerText = "Developer Workspaces",
    ...delegatedProps
  }) => {
    const useStyles = makeStyles((theme) => ({
        root: {
            color: theme.palette.text.primary,
            display: "flex",
            flexFlow: "row nowrap",
            alignItems: "center",
            gap: theme.spacing(1)
        },
        hgroup: {
            marginRight: "auto"
        },
        header: {
            fontSize: "1.5rem",
            lineHeight: 1,
            margin: 0
        },
        subheader: {
            margin: "0",
            fontSize: "0.875rem",
            fontWeight: 400,
            color: theme.palette.text.secondary,
            paddingTop: theme.spacing(0.5)
        },
        subheaderEmphasis: {
            margin: "0",
            fontSize: "0.875rem",
            fontWeight: 500,
            color: theme.palette.text.secondary,
            paddingTop: theme.spacing(0.5),
            fontStyle: "italic"
        },
        headerProps: {
            fontSize: "0.7rem",
            margin: 0,
            color: theme.palette.text.secondary,
            fontStyle: "italic"
        }
    }));

    const { headerId, workspacesConfig } = useWorkspacesCardContext();
    const styles = useStyles();
    const { repoUrl, params, templateName } = workspacesConfig;

    return (
      <div className={`${styles.root} ${className != null ? className : ""}`} {...delegatedProps}>
        <hgroup className={`${styles.hgroup} ${hgroupClassName != null ? hgroupClassName : ""}`}>
          <Typography id={headerId} className={`${styles.header} ${headerClassName != null ? headerClassName : ""}`}>
            {headerText}
          </Typography>
          
          <ViewRepoUrl 
            styles={styles} 
            subheaderClassName={subheaderClassName} 
            activeRepoFilteringText={activeRepoFilteringText} 
            params={params}
            repoUrl={repoUrl}
          />

          {
            templateName && (
              <p className={`${styles.subheaderEmphasis} ${subheaderClassName != null ? subheaderClassName : ""}`}>
                Template: <b>{templateName}</b>
              </p>
            )
          }
        </hgroup>
        {actions}
      </div>
    );
  };

  interface ViewRepoUrlPropTypes {
    display?: boolean,
    styles: any,
    subheaderClassName?: string,
    activeRepoFilteringText?: string, 
    params: any, 
    repoUrl?: string
  }


  /**
   * Component to display a repository URL in specific format.
   *
   * @param {Object} props - The properties object.
   * @param {boolean} props.display - Flag to determine if the component should be displayed.
   * @param {Object} props.styles - The styles object for the component.
   * @param {string} [props.subheaderClassName] - Additional class name for the subheader.
   * @param {string} [props.activeRepoFilteringText] - Text to display when active repo filtering is applied.
   * @param {Object} props.params - Parameters object containing various properties.
   * @param {string} [props.repoUrl] - The repository URL to be displayed.
   *
   * @returns {JSX.Element | null} The JSX element to render or null if display is false or gitRepoUrl is undefined.
   */
  const ViewRepoUrl = ({ display, styles, subheaderClassName, activeRepoFilteringText, params, repoUrl }: ViewRepoUrlPropTypes) => {
    const repoNameRe = /^(?:https?:\/\/)?(?:www\.)?(?:gitlab)\.com\/.*?\/(.+)?$/i;
    function extractRepoName(_repoUrl: string) {
      var _a;
      const [, repoName] = (_a = repoNameRe.exec(_repoUrl)) != null ? _a : [];
      return repoName ? `${repoName}` : "repo URL";
    }

    const gitRepoUrl = params['repo_url'] || repoUrl || undefined;

    return (
        (display && gitRepoUrl) && (
        <p className={`${styles.subheader} ${subheaderClassName != null ? subheaderClassName : ""}`}>
          {activeRepoFilteringText != null ? activeRepoFilteringText : (
            <>
              <b>{params['repoUrl'] ? 'Project catalog repo' : repoUrl ? 'Repo' : ''} :: </b> {extractRepoName(gitRepoUrl)}
            </>
          )}
        </p>
      )
    );
  }



  export default CoderWorkspaceCardHeader;