import { Page, Content } from '@backstage/core-components';
import {
    HomePageCompanyLogo,
    HomePageRecentlyVisited,
    HomePageStarredEntities,
    HomePageToolkit,
    TemplateBackstageLogo,
    HomePageTopVisited,
} from '@backstage/plugin-home';
import { HomePageSearchBar } from '@backstage/plugin-search';
import { Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import { tools, useHomepageStyles } from './shared';
import { Colors } from '../../constants';

const { GREY } = Colors;

const useStyles = makeStyles(theme => ({
    searchBarInput: {
        maxWidth: '60vw',
        margin: 'auto',
        backgroundColor: GREY,
        borderRadius: '50px',
        boxShadow: theme.shadows[1],
    },
    searchBarOutline: {
        borderStyle: 'none',
    },
}));

export const HomePage = () => {
    const classes = useStyles();
    const { svg, path, container, fixedHeight } = useHomepageStyles();

    return (
        <Page themeId="home">
            <Content>
                <Grid container justifyContent="center" spacing={2}>
                    <HomePageCompanyLogo
                        className={container}
                        logo={<TemplateBackstageLogo classes={{ svg, path }} />}
                    />
                    <Grid container item xs={12} justifyContent="center">
                        <HomePageSearchBar
                            InputProps={{
                                classes: {
                                    root: classes.searchBarInput,
                                    notchedOutline: classes.searchBarOutline,
                                },
                            }}
                            placeholder="Search"
                        />
                    </Grid>
                    <Grid container item xs={12}>
                        <Grid item xs={12} md={6}>
                            <HomePageTopVisited />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <HomePageRecentlyVisited />
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} className={fixedHeight}>
                        <Grid item xs={6}>
                            <HomePageToolkit tools={tools} />
                        </Grid>
                        <Grid item xs={6}>
                            <HomePageStarredEntities />
                        </Grid>
                    </Grid>
                </Grid>
            </Content>
        </Page>
    );
};