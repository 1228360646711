import React, {PropsWithChildren} from 'react';
import {makeStyles} from '@material-ui/core';
import ExtensionIcon from '@material-ui/icons/Extension';
import MapIcon from '@material-ui/icons/MyLocation';
import LibraryBooks from '@material-ui/icons/LibraryBooks';
import CreateComponentIcon from '@material-ui/icons/AddCircleOutline';
import LogoFull from './LogoFull';
import LogoIcon from './LogoIcon';
import {Settings as SidebarSettings, UserSettingsSignInAvatar,} from '@backstage/plugin-user-settings';
import {SidebarSearchModal} from '@backstage/plugin-search';
import {
    CatalogIcon,
    Link,
    Sidebar,
    sidebarConfig,
    SidebarDivider,
    SidebarGroup,
    SidebarItem,
    SidebarPage,
    SidebarScrollWrapper,
    SidebarSpace,
    useSidebarOpenState,
} from '@backstage/core-components';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import {Colors} from '../../constants';

const {BLACK} = Colors;

const useSidebarLogoStyles = makeStyles({
    root: {
        width: sidebarConfig.drawerWidthClosed * 3.1,
        height: 2.5 * sidebarConfig.logoHeight,
        display: 'flex',
        flexFlow: 'row nowrap',
        alignItems: 'center',
    },
    link: {
        width: '100%',
        marginLeft: 24,
    },
    hr: {
        width: '100%',
        border: 'none',
        height: '1px',
        margin: '9.6px 0px',
        background: BLACK
    }
});

const SidebarLogo = () => {
    const classes = useSidebarLogoStyles();
    const {isOpen} = useSidebarOpenState();

    return (
        <>
            <div className={classes.root}>
                <Link to="/homepage" underline="none" className={classes.link} aria-label="Home">
                    {isOpen ? <LogoFull/> : <LogoIcon/>}
                </Link>
            </div>
            <hr className={classes.hr}/>
        </>
    );
};

export const Root = ({children}: PropsWithChildren<{}>) => (
    <SidebarPage>
        <Sidebar>
            <SidebarLogo/>
            <SidebarGroup label="Search" icon={<SearchIcon/>} to="/search">
                <SidebarSearchModal/>
            </SidebarGroup>
            <SidebarDivider/>
            <SidebarGroup label="Menu" icon={<MenuIcon/>}>
                {/* Global nav, not org-specific */}
                <SidebarItem icon={CatalogIcon} to="catalog" text="Catalog"/>
                <SidebarItem icon={ExtensionIcon} to="api-docs" text="APIs"/>
                <SidebarItem icon={LibraryBooks} to="docs" text="Docs"/>
                <SidebarItem icon={CreateComponentIcon} to="create" text="Create..."/>
                {/* End global nav */}
                <SidebarDivider/>
                <SidebarScrollWrapper>
                    <SidebarItem icon={MapIcon} to="tech-radar" text="Skills Radar"/>
                </SidebarScrollWrapper>
            </SidebarGroup>
            <SidebarSpace/>
            <SidebarDivider/>
            <SidebarGroup
                label="Settings"
                icon={<UserSettingsSignInAvatar/>}
                to="/settings">
                <SidebarSettings/>
            </SidebarGroup>
        </Sidebar>
        {children}
    </SidebarPage>
);
