import {makeStyles} from '@material-ui/core/styles';
import React from 'react';
import {BsMicrosoftTeams} from 'react-icons/bs';
import {AiOutlineGitlab} from 'react-icons/ai';
import {FaAmazon, FaGlobe, FaMouse} from "react-icons/fa";
import {Colors} from '../../constants';

const {YELLOW} = Colors;

export const useHomepageStyles = makeStyles(theme => ({
    container: {
        margin: theme.spacing(5, 0),
    },
    svg: {
        width: 'auto',
        height: 100,
    },
    path: {
        fill: YELLOW,
    },
    fixedHeight: {
        height: '15.5rem',
        '& > div': {
            height: '100%',
            overflowY: 'scroll'
        },
    }
}));

export const tools = [
    {
        url: 'https://gitlab.com/synechron-code',
        label: 'Gitlab',
        icon: <h1><AiOutlineGitlab/></h1>,
    },
    {
        url: 'http://synefirst.synechron.com',
        label: 'SyneFirst',
        icon: <h1><FaMouse/></h1>,
    },
    {
        url: 'https://teams.microsoft.com/l/team/19%3am0sPMU8s-_BXqbnuteONaqE7GqrM5RcI1Ang1rqC7fQ1%40thread.tacv2/conversations?groupId=1d497900-6085-4f45-8111-c09fee9672c2&tenantId=e50ebc84-76f2-4636-b550-c3f7abc924af',
        label: 'Teams',
        icon: <h1><BsMicrosoftTeams/></h1>,
    },
    {
        url: 'https://myapps.microsoft.com',
        label: 'AWS',
        icon: <h1><FaAmazon/></h1>,
    },

    {
        url: 'https://synechron.sharepoint.com/sites/Practices/Cloud/',
        label: 'The Hub',
        icon: <h1><FaGlobe/></h1>,
    }
];